.breakout {
  background-color: #fff;
  color: #1c1c1c;
  max-width: 400px;
  margin: auto;
  text-align: center;
  padding-top: 5em;
  p {
    margin: 5em 0;
  }
}