.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  background: rgba(0, 0, 0, 0.5);

  > .creditcard {
    position: relative;
    padding: 14px 4px;
    max-width: 400px;
    width: 100%;
    color: #555;
    font-size: 14px;
    background: #fff;
    transition: height 300ms ease;
    overflow-y: auto;
    max-height: 90vh;

    .pay-now-with {
      padding: 0 10px;
      margin-bottom: 20px;
      font-size: 16px;
      img {
        vertical-align: middle;
      }
    }
    
    .demo-card-details{
      padding: 0 10px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }
    .demo-card-details ul {
      list-style: none;
      padding: 0;
    }
    
    .pay-button-wrapper {
      padding: 0 10px;
    }

    .email {
      padding: 0 10px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

      label {
        display: block;
        margin-bottom: 5px;
      }

      input {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 14px;
        color: #555;
        border-radius: 4px;
        height: 34.8px;
        width: calc(100% - 12px);
        border: 1px solid #cccccc;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        padding: 4px 6px;
        margin-bottom: 15px;
      }

      input:focus {
        border-color: rgba(82, 168, 236, 0.8);
        border: 1px solid rgba(82, 168, 236, 1);
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
      }
    }

    iframe {
      width: 100%;
      height: 270px;
      max-height: 100%;
      overflow-y: hidden;
      border: 0;
    }
  }
}

.alert {
  padding: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 10px auto 20px auto;
  width: calc(100% - 40px);
}
