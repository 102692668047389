.donate-now-footer {
  bottom: 0;
  box-shadow: 0 -1px 8px 0 hsla(0, 0%, 46.7%, 0.5);
  left: 0;
  right: 0;
  position: fixed;
  width: 100%;
  background: #fff;
  text-align: center;

  .donate-now-container {
    padding: 14px;
  }

  .copyright {
    border-top: 1px solid #e6e6e6;
    padding: 12px 0;

    img {
      max-width: 400px;
      width: 100%;
    }
  }
}

// /* The Modal (background) */
.Choices-modal {
  position: fixed;
  /* Stay in place */
  z-index: 9100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s linear;
}
.Choices-modal-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s linear;
  .Choices-modal-content {
    bottom: -100vh;
    transition: bottom 0.5s ease;
  }
}

.Choices-modal-content {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fefefe;
  transition: bottom 0.3s ease;
  max-height: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

/*Error Message Container*/
.error-message {
  background: #bf1717;
  color: #fff;

  .inner-container p {
    padding: 0.5rem;
    margin: 0;
  }
}

/* The Close Button */
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  color: #636363;
  font-size: 28px;
  font-weight: bold;
  line-height: 24px;
}

.modal-close:hover,
.modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 20px;
  padding-bottom: 0px;
  text-align: center;
  > * {
    display: block;
    margin: auto;
  }
  .heart {
    margin-bottom: -7px;
  }
  .image-pulse {
    animation: rotation 2s infinite;
    -webkit-animation: rotation 2s infinite;
  }
  .header-text {
    display: inline-block;
    padding: 4px;
    margin: 0px;
    line-height: 32px;
    font-size: 20px;
    color: #000000;
    font-weight: bold;
    img {
      max-width: 48px;
      max-height: 32px;
      vertical-align: middle;
    }
  }
  .current-payment-option {
    margin-top: 10px;
    border: 1px solid #dddddd;
    display: inline-block;
    width: auto;
    padding: 0.2rem 3rem;
    &:hover {
      cursor: pointer;
    }

    .header-text::after {
      border-color: #dddddd;
      border-style: solid;
      border-width: 0.25rem 0.25rem 0 0;
      content: '';
      display: inline-block;
      height: 0.5rem;
      right: -2rem;
      position: relative;
      top: 0.5rem;
      transform: rotate(135deg);
      vertical-align: top;
      width: 0.5rem;
    }
  }
}

.modal-body {
  margin: 0 15px;
  hr {
    margin: auto;
  }
  .custom-container {
    text-align: center;
    margin-top: 20px;

    .amount-container {
      display: flex;

      label {
        padding-right: 10px;
        font-size: 30px;
        line-height: 44px;
        font-weight: 700;
        color: #313131;
        position: relative;
        font-family: Roboto, sans-serif;
      }

      input#custom-amount {
        flex: 1;
        /* Remove First */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        /* Then Style */
        height: 44px;
        line-height: 20px;
        text-align: left;
        color: #787878;
        font-size: 20px;
        font-family: Roboto, sans-serif;

        padding: 7px;
        box-sizing: border-box;

        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #dadada;
      }
    }

    .custom-actions {
      display: flex;

      button {
        flex: 1;
        margin: 7px 0px; // To compensate for HR
      }
      button.back-button {
        margin-right: 5px;
        border: 1px solid #979797;
        background: #fff;
        color: #414141;
      }

      button.confirm-button {
        margin-left: 5px;
        color: #fff;
        padding-top: 0;
        padding-bottom: 0;
        .confirm-donate-text {
          display: block;
          font-size: 10px;
          line-height: 14px;
        }
        .confirm-amount-text {
          display: block;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  .donation-agreement {
    color: #000;
    text-align: center;
    font-size: 14px;
    a {
      text-decoration: none;
    }
  }

  .choice-btn {
    margin: 14px 0px;
    color: #fff;

    .presetAmount {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .choice-custom {
    background-color: #ffffff;
    color: #414141;
    border: 1px solid #979797;
  }

  // Assets CSS
  .heart {
    width: 37.5px;
    height: 32.5px;
    object-fit: contain;
  }
  .hand {
    width: 55px;
    height: 27px;
    object-fit: contain;
  }
  .cross {
    width: 19.5px;
    height: 19.5px;
    object-fit: contain;
  }
  .blue {
    background: #3388ff;
  }
  .purple {
    background: #845cee;
  }
  .red {
    background: #f54858;
  }
  .yellow {
    background: #f9ba03;
  }
  .green {
    background: #33a683;
  }
}

/* Animations */
@-webkit-keyframes rotation {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
@keyframes rotation {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}
