.Campaign {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  color: #1c1c1c;

  .header {
    position: relative;

    .campaign-image {
      display: block;
      position: relative;
      width: 100%;
      min-height: 300px;
      max-height: 300px;
      opacity: 0;
    }
    .campaign-image-loaded {
      opacity: 1;
      transition: opacity 0.4s ease, min-height 0.4s ease, max-height 0.4s ease;
      min-height: 0;
      max-height: 600px;
    }

    .campaign-overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      background: linear-gradient(transparent 5%, rgba(0, 0, 0, 0.6) 80%);
      color: #fff;
      text-decoration: none;
    }
    .bigItem {
      flex: 16 0 0;
    }
    .smallItem {
      flex: 1 0 0;
    }
    .charity-title {
      font-weight: 700;
      font-size: 1.5rem;
    }
    .charity-location {
      font-weight: 300;
      font-size: 1rem;
    }
  }

  .body {
    padding: 0 8px;
    border-top: 2px solid #f3f3f3;
    padding: 0 15px;
    clear: both;
    font-family: Roboto, sans-serif;
    line-height: 21px;
    flex: 1;

    .campaignDetails {
      font-family: Roboto, sans-serif;
      margin-top: 1rem;
      padding-bottom: 10rem;
    }
    .details-heading {
      display: block;
      margin: 10px -15px;
      padding: 5px 9px;
      font-weight: 600;
      font-size: 15px;
      border-left: 6px solid;
    }
    .align-centre {
      text-align: center;
    }
    .details-body {
      color: #2d2d2d;
    }
    .charity-info-line {
      padding-bottom: 0.5em;
    }
    .mini-text {
      font-style: italic;
      font-size: 0.8em;
    }

    .quest-logo {
      margin-bottom: 20px;
    }
  }

  .campaign-closed {
    text-align: center;
    margin: 40px 0;

    .tag-line {
      font-weight: bold;
      font-size: 1.3rem;
    }

    img {
      margin: 20px 0;
    }
  }
}

.Campaign-fade-in {
  animation: campaignFadein 0.3s;
}

@keyframes campaignFadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
