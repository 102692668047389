.barcode {
  min-height: 100%;
  margin: 0 32px;
  text-align: center;

  .white-overlay {
    color: #fff;
    opacity: 0.9;
  }

  .scanner-blue {
    width: 276px;
    height: 280px;
    object-fit: contain;
  }

  .logo-white {
    width: 180px;
  }
  
  .scanner-white {
    width: 276px;
    height: 280px;
    object-fit: contain;
  }

  .welcome-text {
    width: 270px;
    height: 70px;
    object-fit: contain;
  }

  .button {
    background: #4769b2;
    color: #fff;
  }

  .video-container {
    z-index: -1;
    background: #000;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
