.Stats {
  margin: 15px 0;
  .progress-container {
    margin: 8px;
    line-height: 0;
    height: 6px;
    background-color: #e3e3e3;
    border-radius: 6px;
    .progress-bar {
      height: 6px;
      border-radius: 6px;
    }
  }
  .progress-text {
    font-weight: 700;
    color: #000;
  }
  .title {
    color: #767676;
    font-weight: bold;
  }
  .value {
    font-size: 20px;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
}
