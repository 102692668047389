.PaymentTypePicker {
  .modal-close {
    top: 12px;
  }
  .modal-header {
    padding-bottom: 20px;
    text-align: left;
  }

  .payment-option {
    box-sizing: border-box;
    padding: 1rem 2rem;
    border-top: 1px solid #dddddd;
    width: 100%;
    cursor: pointer;
    font-size: 18px;

    .payment-mark {
      vertical-align: middle;
      margin-right: 1rem;
    }
    .check {
      width: 35px;
      margin-left: 1rem;
      order: 1;
    }
    span {
      flex-grow: 1;
    }
  }
  .payment-option-safari {
    opacity: 0.8;
    font-size: 16px;
    .copy-button {
      border: 1px solid #aaa;
      border-radius: 50px;
      display: block;
      padding: 8px 40px;
      background: transparent;
      margin: 10px auto 0;
      font-size: 16px;
      line-height: 24px;
      img {
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
}