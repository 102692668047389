.App {
  max-width: 600px;
  margin: auto;
}

.App-container {
  background-color: #fff;
  color: #1c1c1c;
  text-align: center;
}

.app-header {
  color: #fff;
  height: 40px;
  padding: 5px;
}
.app-header a img {
  border: none;
}

.inner-container {
  max-width: 600px;
  margin: auto;
  box-sizing: border-box;
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}

.flex {
  display: flex !important;
  align-items: center;
}
.flex-center {
  justify-content: center;
}
.grow {
  flex: 1;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}

hr {
  border: none;
  border-bottom: solid 1px #e3e3e3;
  margin: auto -15px;
}
button:focus {
  outline: 0;
}

.button {
  display: block;
  width: 100%;
  border-radius: 5px;
  line-height: 24px;
  padding: 1rem;
  text-align: center;
  font-size: 18px;
  outline: none;
  border-style: none;
}

label,
button {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quest-logo {
  width: 120px;
  height: 50px;
  object-fit: contain;
}
.vertical-spread {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@supports (background: -webkit-named-image(i)) {
  .vertical-spread {
    min-height: -webkit-fill-available;
  }
}
.button.primary-border {
  border-style: solid;
  border-width: 2px;
  background: #fff;
}
.button.primary-bg {
  color: #fff;
}

.demo-only {
  color: red;
  font-size: 18px;
}

.grecaptcha-badge {
  visibility: hidden;
}
