//Social Icons
.social-share-btn {
  width: 70px;
  height: 70px;
}
.inbox {
  width: 59px;
  height: 37px;
}
.btn-no-style {
  border: none;
  background: none;
}

@keyframes thankYouFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.thank-you-container {
	opacity: 1;
	animation-name: thankYouFadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
  animation-duration: 300ms;
  
  .quest-logo {
    margin-top: 30px;
  }

  text-align: center;
  .flex-grid {
    display: flex;
  }
  .restricted {
    height: 200px;
  }
  .col.thank-you-box {
    height: 300px;
  }

  .web-share-btn {
    border: solid 2px #979797;
    background-color: #fff;
    color: #424242;
    width: 71%;
    margin: 20px auto;
    font-size: 20px;
    font-weight: bold;
    img {
      width: 21px;
      height: 21px;
      margin-right: 14px;
      margin-bottom: 2px;
      vertical-align: middle;
    }
  }
  .share-box {
    width: 80%;
    margin: 0 auto;
    h3 {
      color: #424242;
    }
  }
  .flex-grid .col {
    flex: 1;
    text-align: center;
    .thank-you {
      color: #fff;
      margin-top: 6rem;
    }
    .subscription-box {
      margin: 0 auto;
      position: relative;
      width: 80%;
      height: 250px;
      background: #fff;
      top: -5rem;
      border-radius: 5px;
      box-shadow: 0px 1px 5px 0px #7e7e7e;

      .subscription-inner-box .email-icon img {
        margin: 20px;
      }

      .subscription-inner-box .subscription-message p {
        font-size: 0.8rem;
        font-weight: 500;
      }

      .subscription-inner-box .donor-email-field input {
        line-height: 35px;
        border-radius: 5px;
        color: #000000;
        border: 1px solid #dadada;
        text-align: center;
        width: 93%;
        padding: 0;
        margin: 0;
      }

      .subscription-inner-box button {
        width: 90%;
        max-width: 600px;
        z-index: 1;
        margin: 15px auto;
        line-height: 16px !important;
      }

      .subscription-inner-box .subscribed-button {
        background: #fff;
        font-weight: bold;
      }

      p.error {
        color: red;
        font-style: italic;
        position: absolute;
        top: 250px;
        width: 100%;
        margin-top: 0px;
      }
    }
  }
}
